import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { MainLayout } from 'components/layouts/MainLayout';
import { AdminsListPage } from 'pages/AdminsListPage';
import { CommentsPage } from 'pages/CommentsPage';
import { Dashboard } from 'pages/Dashboard';
import { Login } from 'pages/Login';
import { PsychologistsListPage } from 'pages/PsychologistsListPage';
import { RequestsListPage } from 'pages/RequestsListPage';
import { StatisticPage } from 'pages/StatisticPage';
import { TelegramPage } from 'pages/TelegramPage';

function App() {
    return (
        <MainLayout>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact>
                        <Dashboard />
                    </Route>
                    <Route path="/admin/list" exact>
                        <AdminsListPage />
                    </Route>
                    <Route path="/psych/:slug" exact>
                        <PsychologistsListPage source="testu" />
                    </Route>
                    <Route path="/requests/list" exact>
                        <RequestsListPage />
                    </Route>
                    <Route path="/comments/:slug" exact>
                        <CommentsPage />
                    </Route>
                    <Route path="/statistic" exact>
                        <StatisticPage />
                    </Route>
                    <Route path="/telegram/:slug" exact>
                        <TelegramPage />
                    </Route>
                    <Route path="/hr/psych/:slug" exact>
                        <PsychologistsListPage source="hr" />
                    </Route>
                    <Route path="/univer/psych/:slug" exact>
                        <PsychologistsListPage source="univer" />
                    </Route>

                    <Route path="/login" exact>
                        <Login />
                    </Route>

                    <Redirect to="/" />
                </Switch>
            </BrowserRouter>
        </MainLayout>
    );
}

export default App;
