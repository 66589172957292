import axios from 'axios';

export const fetchGeneralStatistic = async () => {
    try {
        const response = await axios.get('statistic/admin/general');

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};
