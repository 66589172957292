export const START_LOADING = 'APP/START_LOADING';
export const FINISH_LOADING = 'APP/FINISH_LOADING';
export const SHOW_ALERT = 'APP/SHOW_ALERT';
export const HIDE_ALERT = 'APP/HIDE_ALERT';

export const ADMIN_AUTH = 'ADMIN/AUTH';
export const ADMIN_LOGOUT = 'ADMIN/LOGOUT';

export const ADMINS_LIST = 'ADMINS/LIST';
export const ADMINS_CHANGE_PASSWORD = 'ADMINS/CHANGE_PASSWORD';

export const PSYCHOLOGISTS_LIST = 'PSYCHOLOGISTS/LIST';
export const PSYCHOLOGISTS_UPDATE = 'PSYCHOLOGISTS/UPDATE';

export const REQUESTS_LIST = 'REQUESTS/LIST';
export const REQUESTS_UPDATE = 'REQUESTS/UPDATE';

export const COMMENTS_LIST = 'COMMENTS/LIST';
export const COMMENTS_UPDATE = 'COMMENTS/UPDATE';
