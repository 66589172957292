import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { adminReducer } from 'redux/adminReducer';
import { adminsReducer } from 'redux/adminsReducer';
import { psychologistsReducer } from 'redux/psychologistsReducer';
import { requestsReducer } from 'redux/requestsReducer';
import { commentsReducer } from 'redux/commentsReducer';

export const rootReducer = combineReducers({
  app: appReducer,
  admin: adminReducer,
  admins: adminsReducer,
  psychologists: psychologistsReducer,
  requests: requestsReducer,
  comments: commentsReducer
});
