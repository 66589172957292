import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { logoutAdmin } from 'redux/actions/setAdminActions';
import { finishLoading, startLoading } from 'redux/actions/setAppActions';
import { ADMIN_AUTH } from 'redux/types';
import { Sidebar } from 'components/Sidebar';
import styles from './ContentLayout.module.scss';

export const ContentLayout = ({ title = '', children }) => {
    const dispatch = useDispatch();
    const { admin } = useSelector((state) => state.admin);
    const history = useHistory();

    useEffect(() => {
        if (!admin) {
            (async () => {
                try {
                    dispatch(startLoading());
                    const { data } = await axios.get('admin/auth');

                    dispatch({ type: ADMIN_AUTH, payload: data });
                } catch (e) {
                    history.push('/login');
                } finally {
                    dispatch(finishLoading());
                }
            })();
        }
    }, [admin, dispatch, history]);

    const logoutHandler = () => {
        dispatch(logoutAdmin());
    };

    return (
        <>
            {admin && (
                <div className={styles.wrapper}>
                    <Sidebar />

                    <div className={styles.main}>
                        <div className={styles.header}>
                            <h3>{title}</h3>

                            <button onClick={logoutHandler}>
                                Выйти из аккаунта
                            </button>
                        </div>
                        <div className={styles.content}>{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};
